/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages

        $('.menu-chat a').click(function(e){
          e.preventDefault();
          LC_API.open_chat_window();
        });

        $('.promo-caption a').click(function(e){
          if($(this).attr('href') === "#chat") {
            e.preventDefault();
            LC_API.open_chat_window();
            
          }
        });

        $('.community .slider').slick({
          autoplay: true,
          dots: true,
          adaptiveHeight: true
        });


	      //// move out of common if nothing but home uses this
	      ///// Equalize height in rows
	      var equalheight = function (container, minsize) {
	      console.log('equalHeight('+container + ',' + minsize +')');
		      var currentTallest = 0,
				      currentRowStart = 0,
				      rowDivs = [],
				      $el,
				      $currentDiv,
				      topPosition = 0;
		      $(container).each(function () {
			      screenWidth = $( window ).width();
			      var classes = $(container).attr('class').split(' ');
			      for(var i = 0; i < classes.length; i++) {
				      //console.log(classes[i]);
			      }

			      $el = $(this);
			      $($el).height('auto');

			      if(screenWidth > minsize){
				      topPostion = $el.position().top;
				      if (currentRowStart !== topPostion) {
					      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
						      rowDivs[currentDiv].height(currentTallest);
					      }
					      rowDivs.length = 0; // empty the array
					      currentRowStart = topPostion;
					      currentTallest = $el.height();
					      rowDivs.push($el);
				      } else {
					      rowDivs.push($el);
					      currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
				      }
				      for (currentDiv = 0; currentDiv < rowDivs.length; currentDiv++) {
					      rowDivs[currentDiv].height(currentTallest);
				      }
			      }
		      });
	      };
	      ////// end equalheight();


	      //// home page stuff
	      function equalizeHeights(){
		      //// home
		      equalheight('.caption', 767);
	      }
	      setTimeout(function(){ equalizeHeights(); }, 1100);

        // stop video playback on modal close
        // http://stackoverflow.com/a/25069916
        $(".video-modal").on('hidden.bs.modal', function (e) {

          console.log('hidden');

          var id = $(this).attr('id');
          var target = "#" + id + ".video-modal iframe";

          $(target).attr("src", $(target).attr("src"));
        });

        $(window).resize(function () {
          equalizeHeights();
        });

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
	      // slick basic
	      $('.slider').slick({
		      autoplay: true,
		      dots: true
	      });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
	  'pricing':{
    	init: function(){
    		//// use a single 'slider' for the hero
		    $('.slider').slick({
			    autoplay:false,
			    dots: false
		    });
	    },
		  finalize: function() {
			  function openTab(evt, tabName) {
				  jQuery( '#pricingresults' ).css('display:block');
				  // variables
				  var i, tabcontent, tablinks;

				  // Get all elements with class="tabcontent" and hide them
				  tabcontent = document.getElementsByClassName("tabcontent");
				  for (i = 0; i < tabcontent.length; i++) {
					  jQuery('.tabcontent').removeClass('active');
				  }

				  // Get all elements with class="tablinks" and remove the class "active"
				  tablinks = document.getElementsByClassName("tablinks");
				  for (i = 0; i < tablinks.length; i++) {
					  tablinks[i].className = tablinks[i].className.replace(" active", "");
				  }

				  // Show the current tab, and add an "active" class to the button that opened the tab
				  //document.getElementById(tabName).style.display = "block";
				  jQuery('#'+tabName+'Tab').addClass('active');
				  jQuery('#'+tabName).addClass('active');
			  }

			  //// make arrays of zips to test against
			  var NCArray = [];
			  var SCArray = [];
			  var WhichToShow = null;
			  jQuery( document ).ready(function() {
			  	//// write zips to console for testing
				  console.log(bs_script_vars.nc_zips);
				  console.log(bs_script_vars.sc_zips);
				  //// get the zip code arrays
				  NCArray = bs_script_vars.nc_zips;
				  SCArray = bs_script_vars.sc_zips;
			  });

			  function zipResult(result){
				  openTab(event, result);
			  }

        $('.tab-change').click(function(e) {
			    e.preventDefault();
			    var tabId = $(this).data('tab');
          openTab(event, tabId);
        });

			  function checkZips(zip){
				  $('#pricingresults').removeClass('hidden');
          $('.no-service').hide();
				  var haveResult = false;
				  for (var i = 0; i < NCArray.length; i++) {
					  if(zip === NCArray[i]){
						zipResult('North');
						  haveResult = true;
						break;
					  }
				  }
				  if(!haveResult){
					  for (var m = 0; m < SCArray.length; m++) {
						  if(zip === SCArray[m]){
							  zipResult('South');
							  haveResult = true;
							  break;
						  }
					  }
				  }
				  if(!haveResult){
					  $( '#Sorry,#SorryMessage,#Disclaimer' ).removeClass( 'hidden' );
            $('.no-service').show();
					  zipResult('Sorry');
				  }else{
					  $( '#Sorry,#Disclaimer' ).removeClass( 'hidden' );
				  }
			  }

			  $( "#zipform" ).submit(function( event ) {
				  event.preventDefault();
				  $('#zipmessage').text('');
				  $('#zipmessage').removeClass('alert alert-danger');
				  var zip = $('#zip').val();
				  if(zip.length<5){
					  $('#zipmessage').text('Please enter 5 digits.');
					  $('#zipmessage').addClass('alert alert-danger');
				  }else{
				  	checkZips(zip);
				  }
			  });

			  ///// open tabs on clicks too
			  $('.tablinks').click(function() {
			  	  var val = $(this).attr('id');
				  openTab(event, val);
			  });

			  //// if a query string has been passed, process it
			  var queryString = window.location.search;
			  if(queryString){
				  var eq = queryString.indexOf("=");
				  var zip2 = queryString.substr(eq+1);
				  setTimeout(function(){ checkZips(zip2); }, 500); //// needs a beat to load the zips arrays
				  document.getElementById("zip").value = zip2; //// fill out the form so the user knows you've already checked it//
			  }
		  }

	  },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
	  'single_broadstripe_landing': {
		  init: function() {
			  console.log('landing page');
		  },
		  finalize: function() {
			  // JavaScript to be fired on the home page, after the init JS
		  }
	  },
	  'services': {
		  init: function() {
			  console.log('services page');//
			  var clickAction = false; //// on first load we want to allow a scroll if there is a hashtag link
			  if(location.hash){
				  clickAction = true;
			  }
			  //// we want to take over the current a hrefs that link to sections & external
			  jQuery('a').click(function(e) {
					  clickAction = true; //// now that something has been clicked don't auto scroll
					  //return false;
			  });
			  jQuery(window).on('hashchange', function (e) {
				  var hash = location.hash;
				  var goToThis = hash.slice( 1 );
				  goToThis ='#'+goToThis+'-section';
				  console.log('go to this = ' + goToThis);
				  ///// we don't want a weird forced scroll when someone just scrolls into a new section - clickaction helps us know
				  if(clickAction){
					  if(goToThis !== '#top-section'){
						  jQuery( 'html, body' ).animate({
							  scrollTop: $(goToThis).offset().top
						  }, 1000, function() {
							  clickAction = false;
						  });
					  }
				  }
			  });
			  if (window.location.hash) {
				  jQuery(window).trigger('hashchange');
			  }
		  },
		  finalize: function() {
			  // JavaScript to be fired on the home page, after the init JS
		  }
	  }

  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
